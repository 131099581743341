import React from "react"
import { StaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Contact from "../components/contact"

import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

export default function ChiSiamo() {
  let imagealt = "Sogno Casa Liguria"

  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPage(filter: { id: { eq: "cG9zdDozNDA5" } }) {
            nodes {
              id
              title
              chiSiamo {
                slider1 {
                  id
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                slider2 {
                  id
                  sourceUrl
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              contatti {
                immagine {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Layout>
          <SEO title="Chi siamo" />
          <BackgroundImage
            Tag="section"
            className="breadcrumb-area jarallax"
            fluid={{
              ...data.allWpPage.nodes[0].contatti.immagine.localFile
                .childImageSharp.fluid,
              sizes: "(max-width: 512px) 80vw",
            }}
            backgroundColor={`#C4BAAD`}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb-inner">
                    <ul className="page-list">
                      <li>Chi siamo</li>
                    </ul>
                    <h1 className="page-title">
                      Realizziamo sogni, <br /> continuamente!
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>

          <div className="container pd-top-100">
            <div className="property-news-single-card style-two col-xl-9 col-lg-8">
              <h4>Una nuova realtà che nasce già grande!</h4>
              <p>
                {" "}
                L’agenzia <b>Sogno Casa Liguria</b>, con sede ad Alassio, è
                specializzata nella vendita di immobili di prestigio nella
                Riviera di Ponente.{" "}
              </p>
              <p>
                Nasce dall’esperienza immobiliare di qualificati professionisti
                del settore casa e opera con serietà e intraprendenza in
                Liguria e in particolare in località turistiche di indiscusso
                valore quali Diano Marina, Alassio, Cervo, Finale Ligure e zone
                limitrofe. {" "}
              </p>
            </div>
          </div>

          <div className="bg-gray-opacity pd-top-100 pd-bottom-100">
            <div className="container">
              <div className="row">
                <div className="col-xl-9 col-lg-8">
                  <div
                    id="carouselExampleFade"
                    className="carousel appartamento-carousel slide"
                    data-ride="carousel"
                  >
                    <div className="carousel-inner apartments-slider-2">
                      {data.allWpPage.nodes[0].chiSiamo.slider1.map(
                        (node, i) => (
                          <div
                            key={i}
                            className={`item carousel-item ${
                              i === 0 ? "active" : ""
                            }`}
                          >
                            <div className="row justify-content-center">
                              <div className="thumb">
                                <Img
                                  fluid={{
                                    ...node.localFile.childImageSharp.fluid,
                                    sizes: "(max-width: 512px) 80vw",
                                  }}
                                  alt={imagealt}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>

                    {(function () {
                      if (data.allWpPage.nodes[0].chiSiamo.slider1.length > 1) {
                        return (
                          <>
                            <a
                              className="carousel-control-prev"
                              href="#carouselExampleFade"
                              role="button"
                              data-slide="prev"
                            >
                              <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Previous</span>
                            </a>
                            <a
                              className="carousel-control-next"
                              href="#carouselExampleFade"
                              role="button"
                              data-slide="next"
                            >
                              <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Next</span>
                            </a>
                          </>
                        )
                      } else {
                        return ""
                      }
                    })()}
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4">
                  <div className="widget widget-owner-info mt-lg-0 mt-5">
                    <div className="contact-info">
                      <h6 className="mb-3">Professionalità e riservatezza</h6>
                      <p>
                        Grazie alla conoscenza capillare del mercato e
                        all’estrema competenza dei suoi consulenti riguardo a
                        tutti gli aspetti tecnici, burocratici e normativi delle
                        vendite immobiliari <b>Sogno Casa Liguria</b>{" "}
                        rappresenta già un importante punto di riferimento nel
                        settore casa.{" "}
                      </p>
                      <p>
                        L’agenzia garantisce ai propri clienti un elevato grado
                        di eccellenza e di riservatezza nei servizi di
                        consulenza e della promozione immobiliare, con
                        l’obiettivo di ottimizzare le trattative, finalizzandole
                        al miglior prezzo e nei tempi più ridotti.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pd-top-100 pd-bottom-100">
            <div className="container">
              <div className="row">
                <div className="col-xl-9 col-lg-8">
                  <div
                    id="carouselExampleFade"
                    className="carousel appartamento-carousel slide chi-siamo-slider2"
                    data-ride="carousel"
                  >
                    <div className="carousel-inner apartments-slider-2">
                      {data.allWpPage.nodes[0].chiSiamo.slider2.map(
                        (node, i) => (
                          <div
                            key={i}
                            className={`item carousel-item ${
                              i === 0 ? "active" : ""
                            }`}
                          >
                            <div className="row justify-content-center">
                              <div className="thumb">
                                <Img
                                  fluid={{
                                    ...node.localFile.childImageSharp.fluid,
                                    sizes: "(max-width: 512px) 80vw",
                                  }}
                                  alt={imagealt}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4">
                  <div className="widget widget-owner-info mt-lg-0 mt-5">
                    <div className="contact-info">
                      <h6 className="mb-3">
                        Troviamo sempre la casa dei tuoi desideri
                      </h6>
                      <p>
                        I nostri clienti troveranno in <b>Sogno Casa Liguria</b>{" "}
                        la soluzione immobiliare ideale, adatta a soddisfare
                        ogni loro esigenza, sia nel caso intendano realizzare un
                        investimento nel cosiddetto “mercato del mattone”, sia
                        nel caso in cui desiderino trovare il luogo ideale per
                        il tempo libero e le proprie emozioni a due passi dal
                        mare di Liguria.
                      </p>
                      <p>
                        Per scoprire la qualità dei nostri servizi e la
                        professionalità del nostro staff, Vi invitiamo a
                        visitare alla prima occasione la nostra{" "}
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="https://goo.gl/maps/y9cWpxkHvtVHEwGu8"
                        >
                          <b className="strong-link">
                            sede di Alassio, in Corso Dante 193.
                          </b>
                        </a>
                         
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Contact bgGrayOpacity="bg-gray-opacity" />
        </Layout>
      )}
    />
  )
}
